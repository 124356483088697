import {extendApi} from '@anatine/zod-openapi'
import {ALL_APPROVAL_CONTRACT_TYPES} from 'constants/enums/approvalContractTypes'
import {ALL_CLIENT_LEGAL_FORMS} from 'constants/enums/clientLegalForms'
import {ALL_CONTRACT_SIGNINGS} from 'constants/enums/contractSignings'
import {ALL_CONTRACT_STATUSES} from 'constants/enums/contractStatuses'
import {ALL_DOCUMENTATION_CHANGE_SEVERITY} from 'constants/enums/documentationChangeSeverity'
import {ALL_DOCUMENTATION_CONTRACT_STATUSES} from 'constants/enums/documentationContractStatuses'
import {ALL_DOCUMENTATION_CONTRACT_TYPES} from 'constants/enums/documentationContractTypes'
import {ALL_FINANCING_TYPES} from 'constants/enums/financingTypes'
import {ALL_MARITAL_STATUSES} from 'constants/enums/maritalStatuses'
import {ALL_OWNERSHIP_TYPES} from 'constants/enums/ownershipTypes'
import {ALL_PAYMENT_LOCATIONS} from 'constants/enums/paymentLocations'
import {ALL_PROCESSES} from 'constants/enums/processes'
import {ALL_REQUEST_APPROVAL_STATUSES} from 'constants/enums/requestApprovalStatuses'
import {ALL_REQUEST_DOCUMENTATION_STATUSES} from 'constants/enums/requestDocumentationStatuses'
import {ALL_ROLES} from 'constants/enums/roles'
import {ALL_SIGNER} from 'constants/enums/signer'
import {ALL_SIGNING_METHODS} from 'constants/enums/signingMethods'
import {ALL_UNIT_HANDOVER_TYPES} from 'constants/enums/unitHandoverTypes'
import {ALL_UNIT_TYPES} from 'constants/enums/unitTypes'
import {ALL_RESOURCES} from 'constants/resources'
import {z} from 'zod'
import {createEnum} from './types'

// General
export const email = (example = 'example@example.com') =>
  extendApi(z.string().email(), {examples: [example]})

export const telephone = () =>
  extendApi(z.string(), {examples: ['+421903123456']})

export const date = () =>
  extendApi(
    z
      .string()
      .trim()
      .regex(/^\d{4}-(0[1-9]|1[0-2])-([0][1-9]|[12][0-9]|[3][01])$/),
    {examples: ['1980-03-24']},
  )

export const requestApprovalStatus = () =>
  createEnum(ALL_REQUEST_APPROVAL_STATUSES)
export const signingMethod = () => createEnum(ALL_SIGNING_METHODS)
export const contractType = () => createEnum(ALL_APPROVAL_CONTRACT_TYPES)
export const contractStatus = () => createEnum(ALL_CONTRACT_STATUSES)
export const contractSigning = () => createEnum(ALL_CONTRACT_SIGNINGS)
export const documentationChangeSeverity = () =>
  createEnum(ALL_DOCUMENTATION_CHANGE_SEVERITY)
export const documentationContractStatuses = () =>
  createEnum(ALL_DOCUMENTATION_CONTRACT_STATUSES)
export const signer = () => createEnum(ALL_SIGNER)
export const contractTypeApproval = () =>
  createEnum(ALL_APPROVAL_CONTRACT_TYPES)
export const contractTypeDocumentation = () =>
  createEnum(ALL_DOCUMENTATION_CONTRACT_TYPES)
export const processes = () => createEnum(ALL_PROCESSES)
export const roles = () => createEnum(ALL_ROLES)
export const resource = () => createEnum(ALL_RESOURCES)

export const paymentLocation = () => createEnum(ALL_PAYMENT_LOCATIONS)
export const unitHandoverType = () => createEnum(ALL_UNIT_HANDOVER_TYPES)
export const unitType = () => createEnum(ALL_UNIT_TYPES)
export const legalForm = () => createEnum(ALL_CLIENT_LEGAL_FORMS)
export const financingType = () => createEnum(ALL_FINANCING_TYPES)
export const ownership = () => createEnum(ALL_OWNERSHIP_TYPES)
export const maritalStatus = () => createEnum(ALL_MARITAL_STATUSES)
export const requestDocumentationStatus = () =>
  createEnum(ALL_REQUEST_DOCUMENTATION_STATUSES)

export type Resource = z.infer<ReturnType<typeof resource>>
