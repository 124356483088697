import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'

const StyledContainer = styled('div')(() => ({
  width: '80px',
}))

const StyledImage = styled('img')({
  verticalAlign: 'middle',
  width: '100%',
  height: 'auto',
})

type BrandProps = ComponentProps<typeof StyledImage> & {
  src: string
  alt: string
}

const Brand: FC<BrandProps> = ({src, alt, ...props}) => {
  return (
    <StyledContainer>
      <StyledImage src={src} alt={alt} {...props} />
    </StyledContainer>
  )
}

export default Brand
