export const ATELIER = 'atelier'
export const APARTMENT = 'apartment'
export const HOUSE = 'house'
export const GARAGE = 'garage'
export const GARAGE_PARKING_SPACE = 'garage_parking_space'
export const OFFICE = 'office'
export const COMMERCIAL_SPACE = 'commercial_space'
export const STORAGE_ROOM = 'storage_room'
export const PARKING_SPACE = 'parking_space'
export const LAND = 'land'
export const STORAGE = 'storage'
export const CELLAR = 'cellar'
export const CELLAR_STORAGE = 'cellar_storage'
export const STUDIO = 'studio'
export const ACCOMMODATION = 'accommodation'
export const GARDEN = 'garden'
export const GARDEN_STORAGE = 'garden_storage'

export const ALL_UNIT_TYPES = [
  ATELIER,
  APARTMENT,
  HOUSE,
  GARAGE,
  GARAGE_PARKING_SPACE,
  OFFICE,
  COMMERCIAL_SPACE,
  STORAGE_ROOM,
  PARKING_SPACE,
  LAND,
  STORAGE,
  CELLAR,
  CELLAR_STORAGE,
  STUDIO,
  ACCOMMODATION,
  GARDEN,
  GARDEN_STORAGE,
] as const

export type UnitType = (typeof ALL_UNIT_TYPES)[number]
