import allType from './allType'
import approvalContractTypes from './approvalContractTypes'
import clientLegalForms from './clientLegalForms'
import contractStatuses from './contractStatuses'
import documentationChangeSeverity from './documentationChangeSeverity'
import documentationContractStatuses from './documentationContractStatuses'
import documentationContractTypes from './documentationContractTypes'
import financingTypes from './financingTypes'
import maritalStatuses from './maritalStatuses'
import ownershipTypes from './ownershipTypes'
import paymentLocations from './paymentLocations'
import processes from './processes'
import requestApprovalStatuses from './requestApprovalStatuses'
import requestDocumentationStatuses from './requestDocumentationStatuses'
import roles from './roles'
import signer from './signer'
import signingMethods from './signingMethods'
import unitHandoverTypes from './unitHandoverTypes'
import unitTypes from './unitTypes'

export const ALL_TYPES = 'allTypes'
export const APPROVAL_CONTRACT_TYPES = 'approvalContractTypes'
export const CLIENT_LEGAL_FORMS = 'clientLegalForms'
export const CONTRACT_STATUSES = 'contractStatuses'
export const DOCUMENTATION_CHANGE_SEVERITY = 'documentationChangeSeverity'
export const DOCUMENTATION_CONTRACT_STATUSES = 'documentationContractStatuses'
export const DOCUMENTATION_CONTRACT_TYPES = 'documentationContractTypes'
export const FINANCING_TYPES = 'financingTypes'
export const MARITAL_STATUSES = 'maritalStatuses'
export const OWNERSHIP_TYPES = 'ownershipTypes'
export const PAYMENT_LOCATIONS = 'paymentLocations'
export const PROCESSES = 'processes'
export const REQUEST_APPROVAL_STATUSES = 'requestApprovalStatuses'
export const REQUEST_DOCUMENTATION_STATUSES = 'requestDocumentationStatuses'
export const ROLES = 'roles'
export const SIGNER = 'signer'
export const SIGNING_METHODS = 'signingMethods'
export const UNIT_TYPES = 'unitTypes'
export const UNIT_HANDOVER_TYPES = 'unitHandoverTypes'

const enums = {
  [ALL_TYPES]: allType,
  [APPROVAL_CONTRACT_TYPES]: approvalContractTypes,
  [CLIENT_LEGAL_FORMS]: clientLegalForms,
  [CONTRACT_STATUSES]: contractStatuses,
  [DOCUMENTATION_CHANGE_SEVERITY]: documentationChangeSeverity,
  [DOCUMENTATION_CONTRACT_STATUSES]: documentationContractStatuses,
  [DOCUMENTATION_CONTRACT_TYPES]: documentationContractTypes,
  [FINANCING_TYPES]: financingTypes,
  [MARITAL_STATUSES]: maritalStatuses,
  [OWNERSHIP_TYPES]: ownershipTypes,
  [PAYMENT_LOCATIONS]: paymentLocations,
  [PROCESSES]: processes,
  [REQUEST_APPROVAL_STATUSES]: requestApprovalStatuses,
  [REQUEST_DOCUMENTATION_STATUSES]: requestDocumentationStatuses,
  [ROLES]: roles,
  [SIGNER]: signer,
  [SIGNING_METHODS]: signingMethods,
  [UNIT_TYPES]: unitTypes,
  [UNIT_HANDOVER_TYPES]: unitHandoverTypes,
} as const

export type Enums = typeof enums
export type Enum = keyof Enums

export default enums
