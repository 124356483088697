export const PROJECT_ACCOUNT = 'project_account'
export const BANK_SECURITY_ACCOUNT = 'bank_security_account'
export const SAFEKEEPING = 'safekeeping'

export const ALL_PAYMENT_LOCATIONS = [
  PROJECT_ACCOUNT,
  BANK_SECURITY_ACCOUNT,
  SAFEKEEPING,
] as const

export type PaymentLocation = (typeof ALL_PAYMENT_LOCATIONS)[number]
