export const NEW = 'new'
export const CLIENT = 'client'
export const APPROVAL = 'approval'
export const REALIZED = 'realized'
export const NOT_REALIZED = 'not_realized'

export const ALL_DOCUMENTATION_CONTRACT_STATUSES = [
  NEW,
  CLIENT,
  APPROVAL,
  REALIZED,
  NOT_REALIZED,
] as const

export type DocumentationContractStatus =
  (typeof ALL_DOCUMENTATION_CONTRACT_STATUSES)[number]
