import * as permissions from 'common/permissions'
import {useMemo} from 'react'
import {useSession} from './auth'

const usePermissions = () => {
  const session = useSession()

  return useMemo(() => {
    if (!session) return null

    return {
      isAdmin: permissions.isAdmin.bind(null, session.user),
      isCreatedBy: permissions.isCreatedBy.bind(null, session.user),
      isAssistant: permissions.isAssistant.bind(null, session.user),
      isUserRequestApproval: permissions.isUserRequestApproval.bind(
        null,
        session.user,
      ),
      canListRequestApprovals: permissions.canListRequestApprovals.bind(
        null,
        session.user,
      ),
      isVPBoss: permissions.isVPBoss.bind(null, session.user),
      isRequestLegal: permissions.isRequestLegal.bind(null, session.user),
      canCreateRequestApproval: permissions.canCreateRequestApproval.bind(
        null,
        session.user,
      ),
      canReadRequestApproval: permissions.canReadRequestApproval.bind(
        null,
        session.user,
      ),
      canUpdateRequestApproval: permissions.canUpdateRequestApproval.bind(
        null,
        session.user,
      ),
      canUpdateRequestApprovalCompanies:
        permissions.canUpdateRequestApprovalCompanies.bind(null, session.user),
      canConfirmRequestApprovalVP: permissions.canConfirmRequestApprovalVP.bind(
        null,
        session.user,
      ),
      canConfirmRequestApprovalUK: permissions.canConfirmRequestApprovalUK.bind(
        null,
        session.user,
      ),
      canGenerateContract: permissions.canGenerateContract.bind(
        null,
        session.user,
      ),
      isUserContractTemplate: permissions.canListContractTemplate.bind(
        null,
        session.user,
      ),
      canCreateContractTemplate: permissions.canCreateContractTemplate.bind(
        null,
        session.user,
      ),
      canListContractTemplate: permissions.canListContractTemplate.bind(
        null,
        session.user,
      ),
      canUpdateContractTemplate: permissions.canUpdateContractTemplate.bind(
        null,
        session.user,
      ),
      canCancelRequestApproval: permissions.canCancelRequestApproval.bind(
        null,
        session.user,
      ),
      canConfirmRequestApprovalContract:
        permissions.canConfirmRequestApprovalContract.bind(null, session.user),
      canSendRequestApproval: permissions.canSendRequestApproval.bind(
        null,
        session.user,
      ),
      canDeclineRequestApprovalContract:
        permissions.canDeclineRequestApprovalContract.bind(null, session.user),
      canFinishRequestApprovalContract:
        permissions.canFinishRequestApprovalContract.bind(null, session.user),
      isUserRequestDocumentation: permissions.isUserRequestDocumentation.bind(
        null,
        session.user,
      ),
      canCreateRequestDocumentation:
        permissions.canCreateRequestDocumentation.bind(null, session.user),
      canListRequestDocumentations:
        permissions.canListRequestDocumentations.bind(null, session.user),
      canUpdateRequestDocumentation:
        permissions.canUpdateRequestDocumentation.bind(null, session.user),
      canConfirmRequestDocumentation:
        permissions.canConfirmRequestDocumentation.bind(null, session.user),
      canPrepareRequestDocumentation:
        permissions.canPrepareRequestDocumentation.bind(null, session.user),
      canSendRequestDocumentation: permissions.canSendRequestDocumentation.bind(
        null,
        session.user,
      ),
      canCancelRequestDocumentation:
        permissions.canCancelRequestDocumentation.bind(null, session.user),
      canDeclineRequestDocumentationContract:
        permissions.canDeclineRequestDocumentationContract.bind(
          null,
          session.user,
        ),
      canFinishRequestDocumentationContract:
        permissions.canFinishRequestDocumentationContract.bind(
          null,
          session.user,
        ),
      canChangeRequestDocumentationContract:
        permissions.canChangeRequestDocumentationContract.bind(
          null,
          session.user,
        ),
      isManager: permissions.isManager.bind(null, session.user),
      isDocumentationLaywer: permissions.isDocumentationLaywer.bind(
        null,
        session.user,
      ),
      canEvalRequestDocumentationContract:
        permissions.canEvalRequestDocumentationContract.bind(
          null,
          session.user,
        ),
    } satisfies Record<
      keyof typeof permissions,
      (...args: unknown[]) => boolean | null
    >
  }, [session])
}

export type Permissions = ReturnType<typeof usePermissions>

export default usePermissions
