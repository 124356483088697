import * as roles from 'constants/enums/roles'

type User = {
  id: string
  role: roles.Role | null
}

type RequestApproval = {
  id: number
  createdById: string
  assistantId: string | null
}

type RequestDocumentation = {
  id: number
  createdById: string
  assistantId: string | null
  managerId: string
}

export const isAdmin = (user: User) => user.role === roles.A

export const isUserRequestApproval = (user: User) =>
  user.role &&
  [roles.VP, roles.ZVP, roles.P, roles.APO, roles.J, roles.UK].includes(
    user.role,
  )

export const canListRequestApprovals = (user: User) =>
  isAdmin(user) || isUserRequestApproval(user)

export const isAssistant = (
  user: User,
  request: RequestApproval | RequestDocumentation,
) => user.id === request.assistantId

export const isCreatedBy = (
  user: User,
  request: RequestApproval | RequestDocumentation,
) => user.id === request.createdById

export const isVPBoss = (user: User) =>
  user.role && [roles.VP, roles.ZVP].includes(user.role)

export const isRequestLegal = (
  user: User,
  request: RequestApproval | RequestDocumentation,
) =>
  user.role &&
  (isVPBoss(user) ||
    user.role === roles.P ||
    (user.role === roles.APO && isAssistant(user, request)))

export const canCreateRequestApproval = (user: User) =>
  isAdmin(user) || isUserRequestApproval(user)

export const canReadRequestApproval = (
  user: User,
  requestApproval: RequestApproval,
) =>
  isAdmin(user) ||
  isRequestLegal(user, requestApproval) ||
  isCreatedBy(user, requestApproval) ||
  user.role === roles.J ||
  user.role === roles.APO // APO can read all request approvals

export const canUpdateRequestApproval = (
  user: User,
  requestApproval: RequestApproval,
) =>
  isAdmin(user) ||
  (canReadRequestApproval(user, requestApproval) && user.role !== roles.J)

export const canCancelRequestApproval = (user: User) =>
  isAdmin(user) || isVPBoss(user)

export const canConfirmRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isVPBoss(user) || isCreatedBy(user, requestApproval)

export const canSendRequestApproval = (user: User) =>
  isAdmin(user) || isVPBoss(user)

export const canDeclineRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isRequestLegal(user, requestApproval)

export const canFinishRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isRequestLegal(user, requestApproval)

export const canGenerateContract = (
  user: User,
  request: RequestApproval | RequestDocumentation,
) => isAdmin(user) || isRequestLegal(user, request)

export const canUpdateRequestApprovalCompanies = (
  user: User,
  requestApproval: RequestApproval,
) =>
  canUpdateRequestApproval(user, requestApproval) &&
  (isRequestLegal(user, requestApproval) || isAdmin(user))

export const canConfirmRequestApprovalVP = (user: User) =>
  user.role && (isAdmin(user) || [roles.VP, roles.ZVP].includes(user.role))

export const canConfirmRequestApprovalUK = (
  user: User,
  requestApproval: RequestApproval,
) =>
  user.role &&
  (isAdmin(user) ||
    (user.role === roles.UK && isCreatedBy(user, requestApproval)))

export const isUserRequestDocumentation = (user: User) =>
  user.role &&
  [
    roles.VP,
    roles.ZVP,
    roles.P,
    roles.APO,
    roles.J,
    roles.RSO,
    roles.MO,
  ].includes(user.role)

export const canListRequestDocumentations = (user: User) =>
  isAdmin(user) || isUserRequestDocumentation(user)

export const canCreateRequestDocumentation = (user: User) =>
  isAdmin(user) || isUserRequestDocumentation(user)

export const canUpdateRequestDocumentation = (user: User) => {
  return isAdmin(user) || isUserRequestDocumentation(user)
}

export const isUserContractTemplate = (user: User) =>
  user.role && [roles.VP, roles.ZVP].includes(user.role)

export const canCreateContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)

export const canListContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)

export const canUpdateContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)

export const isManager = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => user.id === requestDocumentation.managerId

export const isDocumentationLaywer = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => user.role === roles.P && user.id === requestDocumentation.assistantId

export const canConfirmRequestDocumentation = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => isAdmin(user) || isManager(user, requestDocumentation)

export const canPrepareRequestDocumentation = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => isAdmin(user) || isCreatedBy(user, requestDocumentation)

export const canCancelRequestDocumentation = (user: User) =>
  isAdmin(user) || isVPBoss(user)

export const canSendRequestDocumentation = (
  user: User,
  requestDocumentation: RequestDocumentation,
) =>
  isAdmin(user) ||
  isVPBoss(user) ||
  isDocumentationLaywer(user, requestDocumentation)

export const canDeclineRequestDocumentationContract = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => isCreatedBy(user, requestDocumentation) || isAdmin(user) || isVPBoss(user)

export const canFinishRequestDocumentationContract = (
  user: User,
  requestDocumentation: RequestDocumentation,
) =>
  isAdmin(user) ||
  isVPBoss(user) ||
  isDocumentationLaywer(user, requestDocumentation)

export const canChangeRequestDocumentationContract = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => isAdmin(user) || isCreatedBy(user, requestDocumentation)

export const canEvalRequestDocumentationContract = (
  user: User,
  requestDocumentation: RequestDocumentation,
) => isAdmin(user) || isManager(user, requestDocumentation)
