import * as unitTypes from 'constants/enums/unitTypes'

export default {
  [unitTypes.ATELIER]: 'Ateliér',
  [unitTypes.APARTMENT]: 'Byt',
  [unitTypes.HOUSE]: 'Dům',
  [unitTypes.GARAGE]: 'Garáž',
  [unitTypes.GARAGE_PARKING_SPACE]: 'Garážové stání',
  [unitTypes.OFFICE]: 'Kancelář',
  [unitTypes.COMMERCIAL_SPACE]: 'Komerční prostor',
  [unitTypes.STORAGE_ROOM]: 'Komora',
  [unitTypes.PARKING_SPACE]: 'Parkovací stání',
  [unitTypes.LAND]: 'Pozemek',
  [unitTypes.STORAGE]: 'Sklad',
  [unitTypes.CELLAR]: 'Sklep',
  [unitTypes.CELLAR_STORAGE]: 'Sklepní kóje',
  [unitTypes.STUDIO]: 'Studio',
  [unitTypes.ACCOMMODATION]: 'Ubytovací jednotka',
  [unitTypes.GARDEN]: 'Zahrada',
  [unitTypes.GARDEN_STORAGE]: 'Zahradní sklad',
}
